<template>
    <div v-if="booking">
        <div class="flex-container flex-center">
            <div id="view-container" v-if="booking && booking.client">
                <div>
                    <div class="flex-container flex-center title-container">
                        <div>
                            <h2>Booking Details</h2>
                        </div>
                    </div>
                </div>

                <!-- deurcode, email, telefoon -->

                <div class="info-wrapper">
                    <span><span class="info-tag">Booking Number: </span>{{ booking.code }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Door Code: </span>{{ booking.door_code }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Email: </span>{{ booking.client.email }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Phone number: </span>{{ booking.client.phone_number }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Location: </span>{{ booking.xcube.location.name }}</span>
                </div>

                <div class="info-wrapper">
                    <span><span class="info-tag">X-Cube Name: </span>{{ booking.xcube.name }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Team name: </span>{{ booking.team.name }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Player Count: </span>{{ booking.player_count }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Language: </span>{{ booking.language }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Rating: </span>{{ booking.rating }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Location Rating: </span>{{ booking.rating_location }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Experience: </span>{{ booking.experience.name }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Minutes Played: </span>{{ booking.minutes_played }}</span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Score: </span>{{ booking.score }}</span>
                    <font-awesome-icon v-if="
                        booking.results &&
                        booking.results[booking.results.length - 1].success
                    " class="finish-flag-icon" icon="fa-solid fa-flag-checkered" />
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Game State: </span>
                        <status-mark v-bind:checked="booking.minutes_played > 5"></status-mark>
                    </span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Consent to Share: </span>
                        <span class="positive-response" v-if="booking.consent_to_share">Yes</span>
                        <span v-else>No</span>
                    </span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Local Date-Time: </span>
                        <Format :datetime="booking.date" />
                    </span>
                </div>
                <div class="info-wrapper">
                    <span><span class="info-tag">Cube Time: </span>{{
                        booking.xcube.location
                            ? getTimeInTimeZone(
                                booking.date,
                                booking.xcube.location.time_zone
                            )
                            : ""
                    }}</span>
                </div>
                <div class="info-wrapper" v-if="booking.photos && booking.photos.length > 0">
                    <span><span class="info-tag">Photo: </span><img :src="getPhotoUrl(booking.photos[0].url)"
                            class="photo" /></span>
                </div>
                <div class="info-wrapper" v-if="isOperator || isManagement">
                    <span class="info-tag">Refund:</span>
                    <input type="checkbox" :value="true" v-model="booking.refund_requested"
                        @change="requestRefund($event)" />
                </div>
                <div class="info-wrapper" v-if="isOperator || isManagement">
                    <span class="info-tag">Voucher:</span>
                    <input type="checkbox" :value="true" v-model="booking.voucher_requested"
                        @change="requestVoucher($event)" />
                </div>
                <div class="info-wrapper" v-if="isOperator || isManagement">
                    <span class="info-tag">Demo:</span>
                    <input type="checkbox" :value="true" :disabled="isOperator" v-model="booking.demo"
                        @change="toggleDemo($event)" />
                </div>
                <div class="button-container">
                    <el-button @click="back">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" />
                    </el-button>

                    <el-button v-if="canRecoverBooking" @click="recoverBooking">
                        <span>Recover</span>
                    </el-button>
                    <el-button v-if="canDeleteBooking" @click="deleteBooking">
                        <span>Delete</span>
                    </el-button>
                    <el-button @click="moveBooking" v-if="canMoveBooking">
                        <span>Move</span>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Format from "./../shared/Format.vue";
import { getTimeInTimeZone } from "../../helpers/dates";
import { isManagement, isOperator } from "../../helpers/auth";
import StatusMark from "./../shared/StatusMark.vue";

export default {
    components: {
        Format,
        StatusMark,
    },
    data() {
        return {
            booking: undefined,
            rating: 0,
            buttonText: undefined,
        };
    },
    methods: {
        getTimeInTimeZone,
        getPhotoUrl(url) {
            return import.meta.env.VITE_APP_API_URL + url;
        },
        recoverBooking() {
            if (this.isManagement) {
                this.$router.push({
                    name: "admin-bookings-create",
                    params: { booking: this.booking },
                });
            }
            if (this.isOperator) {
                this.$router.push({
                    name: "operator-bookings-create",
                    params: { booking: this.booking },
                });
            }
        },
        moveBooking() {
            this.$router.push({
                name: "bookings-move",
                params: { id: this.booking.id },
            });
        },
        async deleteBooking() {
            if (confirm("Are you sure you want to delete this booking?")) {
                const deleted_at = new Date().toISOString();
                const result = await this.API.updateBooking(this.booking.id, { deleted_at });
                this.booking.deleted_at = result.deleted_at;
            }
        },

        back() {
            let routeName = this.isManagement
                ? "admin-bookings"
                : this.isOperator
                    ? "operator-bookings"
                    : "login";
            this.$router.push({ name: routeName });
        },

        async requestRefund() {
            const result = await this.API.updateBooking(this.booking.id, {
                refund_requested: this.booking.refund_requested,
            });
            this.booking.refund_requested = result.refund_requested;
        },


        async requestVoucher() {
            const result = await this.API.updateBooking(this.booking.id, {
                voucher_requested: this.booking.voucher_requested,
            });
            this.booking.voucher_requested = result.voucher_requested;
        },
        async toggleDemo() {
            const result = await this.API.updateBooking(this.booking.id, {
                demo: this.booking.demo,
            });
            this.booking.demo = result.demo;
        },
    },

    computed: {
        isManagement() {
            return isManagement(this?.API?.user);
        },
        isOperator() {
            return isOperator(this?.API?.user);
        },
        canDeleteBooking() {
            return !this.booking.deleted &&
                (
                    new Date().toISOString() < this.booking.date ||
                    this.isManagement
                );
        },
        canMoveBooking() {
            return !this.booking.minutes_played && (this.isManagement || this.isOperator);
        },
        canRecoverBooking() {
            return this.booking.deleted && (this.isManagement || this.isOperator);
        },
    },
    async mounted() {
        this.booking = this.$route.params.id;

        if (typeof this.booking != "object") {
            this.booking = await this.API.fetchBookingData(this.booking);
        }

        this.buttonText = !this.booking.deleted_at ? "Delete" : "Restore";
        this.rating =
            isNaN(this.booking.rating) || this.booking.rating == null
                ? 0
                : this.booking.rating;
    },
};
</script>


<style lang="scss" scoped>
@import "../../colors";

.finish-flag-icon {
    padding-left: 0.5rem;
    color: $color-info;
}

#view-container {
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #e8de16;
    border-radius: 5px;
    min-width: 50%;
    padding: 50px;

    img {
        width: 17px;
        height: 17px;
        z-index: 1;
        align-content: left;

        &.photo {
            width: 200px;
            height: auto;
        }
    }

    img:hover {
        opacity: 0.8;
    }
}

.flex-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.info-wrapper {
    text-align: left;
}

.bottom-info-wrapper {
    margin-bottom: 15px;
}

.info-wrapper>span {
    font-weight: lighter;
}

.info-tag {
    font-weight: bolder;
    color: #e8de16;
    display: inline-block;
    width: 400px;
    text-align: right;
    margin-right: 10px;
}

.back-button-container {
    position: absolute;
    left: 0;
    top: 0rem;
}

.title-container {
    position: relative;
}

.positive-response {
    color: $color-success;
}

@media screen and (max-width: 1024px) {
    .back-button-container {
        position: absolute;
        left: -30px;
        top: -20%;
    }

    .info-tag {
        font-weight: bolder;
        color: #e8de16;
        display: inline-block;
        width: 120px;
        text-align: left;
        margin-right: 0px;
        font-size: 15px;
    }

    .info-wrapper {
        text-align: left;
        margin-left: -40px;
        font-size: 15px;
    }
}
</style>