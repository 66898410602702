import fetchRetry from "fetch-retry";

const fetchWithRetry = fetchRetry(fetch, {
    retries: 3,
    retryDelay: 2000 // 2 seconds
});

export const downloadFile = async (sUrl) => {
    const response = await fetchWithRetry(sUrl);
    if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = sUrl.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error(`HTTP error! status: ${response.status}`);
    }
}